import React from 'react';
import './portfolio.component.css';
export default class Portfolio extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id='portfolio'>Portfolio</section>
    )
  }
}
