import React from 'react';
import './testimonials.component.css';
export default class Testimonials extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id='testimonials'>Testimonials</section>
    )
  }
}
