import React from 'react';
import './about.component.css';
import Me from '../../assets/me-about.jpg';
import {FaAward} from "react-icons/fa";
import {FiUsers} from "react-icons/fi";
import {VscFolderLibrary} from "react-icons/vsc";

export default class About extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id='about'>
        <h5>Get To Know</h5>
        <h2>About Me</h2>

        <div className="container about__container">
          <div className="about__me">
            <div className="about__me-img">
              <img src={Me} alt="About Me image"/>
            </div>
          </div>
          <div className="about__content">
            <div className="about__cards">

              <article className="about__card">
                <FaAward className='about__icon' />
                <h5>Experience</h5>
                <small>3+ Years Working</small>
              </article>

              <article className="about__card">
                <FiUsers className='about__icon' />
                <h5>Clients</h5>
                <small>200+ Worldwide</small>
              </article>

              <article className="about__card">
                <VscFolderLibrary className='about__icon' />
                <h5>Projects</h5>
                <small>80+ Completed</small>
              </article>

            </div>

            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam, assumenda commodi consequuntur, cupiditate dignissimos ducimus enim facere incidunt iste maxime minima nihil non repellat sapiente sequi sint soluta totam. Consequatur?
            </p>

            <a href="#" className='btn btn-primary'>Let's Talk</a>

          </div>
        </div>
      </section>
    )
  }
}
