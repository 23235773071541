import React from 'react';
import './footer.component.css';
export default class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>Footer</div>
  }
}
