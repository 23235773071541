import React from 'react';
import './contact.component.css';
export default class Contact extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id='contact'>Contact</section>
    )
  }
}
