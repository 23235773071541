import React from 'react';
import './services.component.css';
export default class Services extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id='services'>Services</section>
    )
  }
}
